import axios from "axios";
import ElementUI from "element-ui";
import $ from "jquery";
import router from "../router";

const http = axios.create({
  timeout: 5000,
});

if (process.env.NODE_ENV == "development") {
  http.defaults.baseURL = "api";
} else {
  http.defaults.baseURL = process.env.VUE_APP_BASE_API;
}

// 配置请求拦截器
http.interceptors.request.use(
  (config) => {
    if (config.method === "post") {
      config.params = {
        storeId: process.env.VUE_APP_STORE_ID,
        storeType: process.env.VUE_APP_STORE_TYPE,
        ...config.params,
      };
      // if (config.params.exportType && config.params.exportType == 1) {
      //   config.headers = {
      //     'Content-Type': 'application/octet-stream;charset=utf-8'
      //   }
      //   config.responseType = 'blob'
      // }
    }
    return config;
  },
  (err) => {
    console.log("请求未到服务器，失败");
    return Promise.reject(err);
  }
);

// 配置响应拦截器
http.interceptors.response.use(
  (res) => {
    if (res.data.code == "200") {
      ElementUI.Message({
        message: "登录成功",
        type: "success",
        offset: 100,
      });
    } else {
      ElementUI.Message({
        message: res.data.message,
        type: "error",
        offset: 100,
      });
    }
    return res;
  },
  (error) => {
    console.error(error);
    ElementUI.Message({
      message: error,
      type: "error",
      offset: 100,
    });
  }
);
export function _post(data) {
  let pc_user = JSON.parse(localStorage.getItem("pc_user"));
  pc_user ? (data.access_id = pc_user.access_id) : null;
  return new Promise((resolve, reject) => {
    $.ajax({
      url:
        process.env.VUE_APP_BASE_API +
        "?store_id=" +
        process.env.VUE_APP_STORE_ID +
        "&store_type=" +
        process.env.VUE_APP_STORE_TYPE,
      type: "POST",
      data: data,
      success: function (res) {
        if (typeof res == "string") {
          res = JSON.parse(res);
        }
        if (res.code == "203") {
          localStorage.removeItem("pc_user");
          ElementUI.Message({
            message: res.message,
            type: "error",
            offset: 100,
          });
          setTimeout(() => {
            router.push("/login");
          }, 1000);
          return;
        } else if (res.code != "203" && res.code != "200") {
          ElementUI.Message({
            message: res.message,
            type: "error",
            offset: 100,
          });
        }
        resolve(res);
      },
      error: function (data) {
        data.message = "请求失败！";
        reject(data);
      },
    });
  });
}

export function _postNoMessage(data) {
  let pc_user = JSON.parse(localStorage.getItem("pc_user"));
  pc_user ? (data.access_id = pc_user.access_id) : null;
  return new Promise((resolve, reject) => {
    $.ajax({
      url:
        process.env.VUE_APP_BASE_API +
        "?store_id=" +
        process.env.VUE_APP_STORE_ID +
        "&store_type=" +
        process.env.VUE_APP_STORE_TYPE,
      type: "POST",
      data: data,
      success: function (res) {
        if (typeof res == "string") {
          res = JSON.parse(res);
        }
        if (res.code == "203") {
          localStorage.removeItem("pc_user");
          ElementUI.Message({
            message: res.message,
            type: "error",
            offset: 100,
          });
          setTimeout(() => {
            router.push("/login");
          }, 1000);
          return;
        }
        resolve(res);
      },
      error: function (data) {
        data.message = "请求失败！";
        reject(data);
      },
    });
  });
}
export function _postForm(query, body) {
  let formData = new FormData();
  for (let key in body) {
    formData.append(key, body[key]);
  }
  let url =
    process.env.VUE_APP_BASE_API +
    "?store_id=" +
    process.env.VUE_APP_STORE_ID +
    "&store_type=" +
    process.env.VUE_APP_STORE_TYPE;
  for (let key in query) {
    url += "&" + key + "=" + query[key];
  }
  let pc_user = JSON.parse(localStorage.getItem("pc_user"));
  pc_user ? (url += "&accessId=" + pc_user.access_id) : null;
  return new Promise((resolve, reject) => {
    $.ajax({
      url: url,
      type: "POST",
      data: formData,
      processData: false, // 不处理数据
      contentType: false, // 不设置内容类型
      success: function (res) {
        if (typeof res == "string") {
          res = JSON.parse(res);
        }
        if (res.code == "203") {
          localStorage.removeItem("pc_user");
          ElementUI.Message({
            message: res.message,
            type: "error",
            offset: 100,
          });
          setTimeout(() => {
            router.push("/login");
          }, 1000);
          return;
        } else if (res.code != "203" && res.code != "200") {
          ElementUI.Message({
            message: res.message,
            type: "error",
            offset: 100,
          });
        }
        resolve(res);
      },
      error: function (data) {
        data.message = "请求失败！";
        reject(data);
      },
    });
  });
}
export function _upImg(data) {
  return new Promise((resolve, reject) => {
    this.pc_user = JSON.parse(localStorage.getItem("pc_user"));
    $.ajax({
      url:
        process.env.VUE_APP_BASE_API +
        "?store_id=" +
        process.env.VUE_APP_STORE_ID +
        "&store_type=" +
        process.env.VUE_APP_STORE_TYPE +
        "&module=app_pc&action=product&m=uploadImgs&access_id=" +
        this.pc_user.access_id,
      type: "POST",
      data: data,
      contentType: false,
      processData: false,
      success: function (res) {
        resolve(res);
      },
      error: function (data) {
        data.message = "请求失败！";
        reject(data);
      },
    });
  });
}
export function _upFile(data) {
  return new Promise((resolve, reject) => {
    this.pc_user = JSON.parse(localStorage.getItem("pc_user"));
    $.ajax({
      url:
        process.env.VUE_APP_BASE_API +
        "?store_id=" +
        process.env.VUE_APP_STORE_ID +
        "&store_type=" +
        process.env.VUE_APP_STORE_TYPE +
        "&api=resources.file.uploadFilesForUser&access_id=" +
        this.pc_user.access_id,
      type: "POST",
      data: data,
      contentType: false,
      processData: false,
      success: function (res) {
        resolve(res);
      },
      error: function (data) {
        data.message = "请求失败！";
        reject(data);
      },
    });
  });
}
