import Vue from 'vue';
import Vuex from 'vuex';
import {_post} from '@/utils/http'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pagePath: '/home', //当前选中菜单索引
    visibleBanner: true, // 是否显示banner
    activityId: ''
  },
  mutations: {
    changePagePath(state, val) { 
        state.pagePath = val;  
    },
    changeVisibleBanner(state, val) {
        state.visibleBanner = val;
    },
    changeActivityId(state, val) {
        state.activityId = val;
    }
  },
  actions: { 
    async getActivityId({ commit }) { 
      const res = await _post({api: 'pc.regist.activity.activityList'})
      commit('changeActivityId', res.data.list[0].id)
    }
  },
  getters: {
    pagePath: state => state.pagePath,
    visibleBanner: state => state.visibleBanner,
    activityId: state => state.activityId,
  }
});