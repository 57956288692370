import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// jq
import $ from "jquery";
window.$ = $;
// element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import {
  _post,
  _postNoMessage,
  _upImg,
  _upFile,
  _postForm,
} from "./utils/http";
Vue.prototype._post = _post;
Vue.prototype._postNoMessage = _postNoMessage;
Vue.prototype._upImg = _upImg;
Vue.prototype._upFile = _upFile;
Vue.prototype._postForm = _postForm;

import zh_CN from "./utils/zh_CN";
Vue.prototype.language = zh_CN;

Vue.config.productionTip = false;

Vue.mixin({
  data() {
    return {
      pu_user: {},
    };
  },
  created() {
    this.pc_user = JSON.parse(localStorage.getItem("pc_user"));
  },
  filters: {
    GMTConvertYMDHMS(time) {
      var date = time.substr(0, 10); //年月日
      var hours = time.substring(11, 13);
      var minutes = time.substring(14, 16);
      var seconds = time.substring(17, 19);
      var timeFlag = date + " " + hours + ":" + minutes + ":" + seconds;
      timeFlag = timeFlag.replace(/-/g, "/");
      timeFlag = new Date(timeFlag);
      timeFlag = new Date(timeFlag.getTime() + 8 * 3600 * 1000);
      timeFlag =
        timeFlag.getFullYear() +
        "-" +
        (timeFlag.getMonth() + 1 < 10
          ? "0" + (timeFlag.getMonth() + 1)
          : timeFlag.getMonth() + 1) +
        "-" +
        (timeFlag.getDate() < 10
          ? "0" + timeFlag.getDate()
          : timeFlag.getDate()) +
        " " +
        (timeFlag.getHours() < 10
          ? "0" + timeFlag.getHours()
          : timeFlag.getHours()) +
        ":" +
        (timeFlag.getMinutes() < 10
          ? "0" + timeFlag.getMinutes()
          : timeFlag.getMinutes()) +
        ":" +
        (timeFlag.getSeconds() < 10
          ? "0" + timeFlag.getSeconds()
          : timeFlag.getSeconds());
      return timeFlag;
    },
  },
  methods: {
    toUrl(url) {
      this.$router.push(url);
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
