<template>
  <div id="page-head">
    <div class="head">
      <!-- logo start -->
      <div class="logo" @click="toUrl(class_list[0])">
        <img src="../static/common/logo.png" alt />
      </div>
      <!-- nav start -->
      <div class="nav">
        <ul>
          <li
            v-for="(item, index) of class_list"
            :key="index"
            :class="{ active: pagePath == item.url }"
            @click="toUrl(item)"
          >
            <div class="menu">{{ item.name }}</div>
            <div class="child-menu" v-if="item.hasChild">
              <div class="w">
                <span
                  :id="child.id"
                  :class="{ active: childId == child.id }"
                  v-for="child in item.child"
                  :key="child.id"
                  @click.stop="menuShowClick(item.id, child.id)"
                >{{ child.name }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- nav end -->
    <div class="banner" v-if="visibleBanner">
      <el-carousel :interval="5000" arrow="always" :height="imageHeight + 'px'">
        <el-carousel-item
          v-for="item in banner"
          :key="item.id"
          :style="{ height: imageHeight + 'px' }"
        >
          <el-image
            class="bannerImg"
            fit="cover"
            :src="item.image"
            alt
            :style="{ height: imageHeight + 'px' }"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "nHead",
  data() {
    return {
      class_list: [
        {
          name: "首页",
          id: 0,
          url: "/home"
        },
        {
          name: "关于大会",
          id: 1,
          url: "/aboutus"
        },
        {
          name: "同期活动",
          id: 2,
          url: "/activity"
        },
        {
          name: "动态资讯",
          id: 3,
          url: "/news"
        },
        // {
        //   name: "大会服务",
        //   id: 4,
        //   url: "/service"
        // },
        // {
        //   name: "往期回顾",
        //   id: 5,
        //   url: "/"
        // },
        {
          name: "用户中心",
          id: 6,
          url: "/my"
        }
      ],

      imageHeight: 0,
      banner: []
    };
  },
  computed: {
    pagePath() {
      return this.$store.state.pagePath;
    },
    visibleBanner() {
      return this.$store.getters.visibleBanner;
    }
  },
  created() {
    this.getNewType();
    this.axios();
  },
  mounted() {
    let n = 640 / 1920;
    if (window.innerWidth) {
      var imageWidth = window.innerWidth;
      this.imageHeight = imageWidth * n;
    } else if (
      document.documentElement &&
      document.documentElement.clientWidth
    ) {
      imageWidth = document.documentElement.clientWidth;
      this.imageHeight = imageWidth * n;
    } else {
      imageWidth = document.body.offsetWidth;
      this.imageHeight = imageWidth * n;
    }

    window.addEventListener("resize", () => {
      // 变化后需要做的事
      if (window.innerWidth) {
        imageWidth = window.innerWidth;
        this.imageHeight = imageWidth * n;
      } else if (
        document.documentElement &&
        document.documentElement.clientWidth
      ) {
        imageWidth = document.documentElement.clientWidth;
        this.imageHeight = imageWidth * n;
      } else {
        imageWidth = document.body.offsetWidth;
        this.imageHeight = imageWidth * n;
      }
    });
  },
  methods: {
    toUrl(item) {
      this.$store.commit("changePagePath", item.url);
      // 跳转添加回调，解决开发环境重复点击导航栏报错\报警
      this.$router.push({ path: item.url }, () => {});
    },
    axios() {
      var data = {
        module: "app_pc",
        action: "index",
        m: "home",
        cid: ""
      };

      this._post(data).then(res => {
        this.banner = res.data.banner.filter(item => item.image);
      });
    },
    // 获取动态资讯的id
    getNewType() {
      var data = {
        api: "admin.channel.list",
        parentId: 0,
        status: 1,
        pageSize: 99
      };
      this._post(data).then(res => {
        if (res.code == "200") {
          res.data.list.forEach(item => {
            if (item.name == "动态资讯") {
              this.$set(this.class_list, 3, {
                name: "动态资讯",
                id: 3,
                url: "/news?id=" + item.id
              });
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#page-head {
  /* position: fixed; */
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.head {
  height: 104px;
  display: flex;
  justify-content: center;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
/* logo css */
.logo {
  height: 64px;
  width: 700px;
  padding: 18px 0;
  cursor: pointer;
}

.logo-title .logo-img {
  height: 74px;
}
.nav {
}
.nav ul {
  display: flex;
}
.nav li {
  position: relative;
  padding: 0 30px;
  overflow: hidden;
  line-height: 104px;
  height: 104px;
  cursor: pointer;
}
.nav li.active {
  font-weight: bold;
  color: var(--main-color);
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -14px;
    width: 28px;
    height: 2px;
    opacity: 1;
    background: var(--main-color);
  }
}
.nav li .menu {
  font-size: 18px;
}
.nav li .child-menu {
  position: absolute;
  opacity: 0;
  height: 0;
  padding-top: 20px;
  color: #333;
}
.nav li:hover {
  overflow: initial;
  color: var(--main-color);
}
.nav li:hover .menu {
}
.nav li:hover .child-menu {
  display: block;
  word-break: break-all;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;
  height: auto;
  width: 120px;
  padding: 0 20px;
}
.nav li:hover .child-menu span {
  display: block;
  line-height: 48px;
}
.nav li:hover .child-menu span:hover {
  color: #156bfd;
}
.bannerImg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  /* width: 100vw; */
  /* min-width: 1200px; */
  height: 640px;
  cursor: pointer;
  object-fit: cover;
  top: 104px;
}
</style>