const zh_CN = {
    title:'来客推商城欢迎您!',
    title2:'来客推商城',
    lang:['中文','English','繁體'],
    pageTitile:['个人中心','商家入驻'],
    cancel:'取消',
    confirm:'确认',
    common_button:['删除','保存','确定'],
    cpu:['立即领取','去使用','已抢光'],
    hwzc: '您还未注册商城账号，请设置密码完成注册',
    home:{
        login:'登录',
        register:'注册',
        sign_out:'退出',
        My_order:'我的订单',
        My_account:'我的账户',
        My_collection:'我的收藏',
        coupon:'优惠券',
        Language_switch:'语言切换',
        My_shop:'我的店铺',
        message:'消息',
        Customer_service:'客服服务',
        Download_app:'下载APP',
        Recent_search:'最近搜索',
        New_products:'最新加入的商品',
        delete:'删除',
        common:'共',
        Items:'件商品',
        total:'合计：',
        Goshopping_cart:"去购物车",
        home_page:'首页',

    },
    Popup:['店铺申请正在审核中','您的资料已提交，正在审核中，请耐心等待','我知道了','审核未通过','再次申请'],
    home_Tips:['删除成功','请填写搜索商品、品牌','搜索商品、品牌'],
    home_index:{
        Hot_sale:'热销推荐',
        More_sales:'更多热销',
        shelf:'已下架',
        New_Hot:'新品热卖',
        More_products:'更多新品',
		seckill: '限时秒杀',
		moreSeckill: '更多抢购',
		seckillTitle: '点场',
		countdown: '倒计时',
		seckillTips: ['已抢', '件'],
		seckillPrice: '抢购价',
		snapup: "立即抢购",
		scanCodeWX: '扫码进入小程序立即抢购'
    },
    address:{
        Receiving_address:'收货地址',
        add_address:'新增收货地址',
        consignee:'收货人',
        number:'联系电话',
        modify:'修改',
        Default_address:'默认地址',
        Set_default:'设为默认',
        edit:'编辑',
        add:'新增',
        Location:'所在地区',
        Detailed_address:'详细地址',
        consignee2:'收 货 人',
        phone:'手机号码',
        set_address:'设为默认地址'
    },
    address_Tips:['你还没有收货地址哦','请选择省/市/区','请输入信息地址信息，如小区名称、楼栋号、单元等信息','请输入收货人姓名',
    '请输入手机号码','设置成功','请选择所在地区','请填写详细地址','请填写收货人姓名','收货人姓名包含特殊字符','请填写手机号码','修改成功','添加成功',''],
    afterSale:{
        title:'售后管理',
        search:'搜索',
        Commodity_information:'商品信息',
        refund:'退款金额',
        Application_time:'申请时间',
        Service_type:'服务类型',
        After_state:'售后状态',
        action:'操作',
        shop:'店铺：',
        order_number:'订单号：',
        type:['退货退款','退款','换货','退款中','审核通过','退款失败','商品寄回中','退款成功','售后结束','换货中','换货失败','成功','换货成功','审核中','审核通过','拒绝'],
        button:['寄回商品','确认收货','查看详情','撤销审核','查看物流'],
        placeholder:['请选择/填写快递名称','请输入快递单号','请输入联系人姓名','请输入联系人电话','请输入商品名称或订单号搜索'],
        Popup:['填写物流信息','快递名称','快递单号','联 系 人','联系电话',''],
        Tips:['你还没有相关的订单哦','请选择快递名称','请填写快递单号','请填写联系人姓名','请填写联系人电话','输入手机号错误，请重新输入！'],
    },
    myintegral:{
        title:'我的积分',
        points:'当前可用积分',
        detailed:['全部明细','获取明细','使用明细','详细说明','积分变化','时间']
    },
    myWallet:{
        title:'我的钱包',
        My_balance:'我的余额',
        Recharge:'充值',
        Withdrawal:'提现',
        My_balance2:'我的余额（元）',
        Wallet_details:'钱包明细',
        detailed:['详细说明','金额变化','时间'],
        type:['充值','申请提现','分享','余额消费','退款','系统扣款','给好友转余额','转入余额','系统充值','消费金解封','抽奖中奖','提现成功','提现失败','取消订单','分享获取红包','交竞拍押金','退竞拍押金','会员返现']

    },
    recharge:{
        title:'充值',
        Tips:['请填写充值金额','充值金额','元','请选择支付方式','支付宝扫一扫支付','微信扫一扫支付','获取验证码','请输入充值金额','s后可重获',''],
        Confirm_recharge:'确认充值',
        Recharge_success:'充值成功',
        GO:'去逛逛',
        myWallet:'我的钱包',

    },
    withdrawal:{
        title:'提现',
        title2:'请填写提现金额',
        title3:'请填写提现银行卡信息',
		selectBrank: "请选择银行卡",
		selectBrankPH: "请选择提现银行卡",
		addBrank: "添加银行卡",
		tail: "尾号",
        Withdrawal_all:'全部提现',
        Confirm_Withdrawal:'确认提现',
        placeholder:['请输入银行卡号','验证银行卡号','请输入支行名称','请输入持卡人姓名','请输入手机号码','获取验证码'],
        label:['提现金额','银行卡号','开户银行','支行名称','持卡人','手机号码','验证码'],
        Tips:['可提现金额：','请填写银行卡号','请验证银行卡号','请前往安全中心，绑定手机号码','请输入验证码','请填写正确的提现金额','提现成功']
    },
	myBankCard: {
		title: "我的银行卡",
		Default: '默认',
		setDefault: '设为默认',
		editor: "修改",
		unbind: "解绑",
		addbrand: "添加银行卡",
		brandCard: "银行卡号",
		brandCardPH: '请输入银行卡号',
		bank: '开户银行',
		bankPH: "请输入银行名称",
		branch: '开户支行',
		branchPH: "请输入支行名称",
		cardholder: "持卡人",
		cardholderPH: "请输入持卡人姓名",
		cancel: '取消',
		save: '保存',
		untieCard: "确认要解绑该银行卡吗？",
		confirm: "确定",
		validName: "请输入合法的姓名",
		validCard: '请输入正确的银行卡号',
	},
    My_collection:{
        title:'我的收藏',
        tab:['商品收藏','店铺收藏'],
        shelf:'已下架',
        shop:'店铺：',
        Look:'看相似',
        closed:'已打烊',
        collection:'人收藏',
        Go:'进店逛逛',
        service:'联系客服',
        Tips:['你还没有收藏商品哦','你还没有收藏店铺哦']
    },
    similar:{
        title:'看相似',
        shop:'店铺：',
        Already_collected:'已收藏',
        Similar_goods:'相似商品',

    },
    mycoupon:{
        title:'优惠券',
        title2:'领取更多优惠券',
        Immediate_use:'立即使用',
        Go:'去领券中心',
        tab:['未使用','已使用','已过期'],
        vouchers:['免邮券','满减券','折扣券','会员赠券','兑换券'],
        Tips:['免邮','折','到期','目前没有','的优惠券！']
    },
    coupon:{
        title:'首页',
        title2:'领券中心',

        Tips:['去使用','已抢光','已抢','暂无可领优惠券！','领取成功']
    },
    //暂未找到
    groupOrder:{
        tabs:['全部','待付款','拼团中','成功','失败'],
        search:'搜索',
        table_th:['商品信息','单价','数量','实付款','订单状态','拼团状态'],

    },
    login:{
        title:'全国农商互联暨乡村振兴产销对接大会 - 登录',
        title2:'商城首页',
        Register_now:'立即注册',
        Forget:'忘记密码',
        tab:['密码登录','验证码登录'],
        placeholder:['账号/手机号','手机号','密码','手机验证码','获取验证码','验证码','登录','自动登录'],
        footer:['关于我们','新手指南','支付方式','帮助中心','售后服务','中天联动（北京）网络技术有限公司 版权所有[官方网站]',' 京ICP备2020040306号','京公网安备 11010802034296号','出版物经营许可证','营业执照'],
        Tips:['还不是会员？','登录成功','请输入手机号！','发送成功','账号/手机号不能为空','密码不能为空','您输入的密码小数6位，请重新输入！','手机号不能为空','您输入的手机号码错误，请重新输入！','手机验证码不能为空','验证码不能为空','验证码不正确','登录成功']
    },
    register:{
        title:'全国农商互联暨乡村振兴产销对接大会 - 注册',
        title2:'商城首页',
        register:'用户注册',
        register2:'注册',
        login:'立即登录',
        placeholder:['设置账号（6-15位字母或数字）','设置密码（6-16位数的新密码）','手机号码','手机验证码','获取验证码','验证码',''],
        Tips:['注册代表你已同意','来客电商用户协议','已经是会员，','请输入手机号！','发送成功','短信发送频率超限！','请输入账号为6-15位字母或数字','账号不能为空','您输入的账号包含特殊字符，请重新输入！','密码不能为空','您输入的密码小数6位，请重新输入！','您输入的密码包含空格，请重新输入！','手机号不能为空','您输入的手机号码错误，请重新输入！','手机验证码不能为空','验证码不能为空','验证码不正确','请勾选注册协议']
    },
    forget:{
        title:'全国农商互联暨乡村振兴产销对接大会 - 找回密码',
        title2:'找回密码',
        title3:'身份验证',
        title4:'重置密码',
        Back_login:'返回登录',
        placeholder:['请输入手机号','手机验证码','设置密码（6-16位数的新密码）','再次输入密码','验证码'],
        Tips:['手机号不能为空','手机验证码不能为空','密码不能为空','您输入的密码包含空格，请重新输入！','您输入的密码小数6位，请重新输入！','确认密码与密码不一致','验证码不能为空','验证码不正确','密码重置成功','请输入手机号！','您输入的手机号码错误，请重新输入！','发送成功','短信发送频率超限！']
    },
    mch:{
        title:'店铺：',
        closed:'已打烊',
        coupons:'优惠券',
        sold:'已售',
        piece:'件',
        recommend:'店家推荐',
        shelf:'已下架',
        All_products:'全部商品',
        Selected_conditions:'已选条件：',
        empty:'清空筛选条件',
        class:'分类',
        more:'更多',
        Put:'收起',
        Checked:['单选','多选'],
        price:'价格',
        default:'默认',
        sales_volume:'销量',
        add_shop:'添加商品',
        num:'数量',
        Collect_coupons:'领券',
        fracture:'折',
        collection:['收藏','已收藏','取消收藏','人收藏'],
        long:['件在售商品','已有','查看门店信息','有效期','营业时间：','联系电话：','店铺名称：','联系地址：'],
        Tips:['暂无门店信息','领取成功','库存不足，请选择其它!','库存清单不存在此属性','请选择属性！','添加成功','暂无线下门店，敬请期待~'],
		mch: "店铺"
    },
    applyShop:{
        title:'商家入驻',
        Move:'立即入驻',
        I_know:'我知道了',
        label:['店铺名称','店铺信息','经营范围','用户姓名','身份证号','联系电话','所在地区','详细地址','营业执照','上传图片'],
        radio:['个人','企业'],
        placeholder:['请填写店铺名称','请填写店铺信息','请填写经营范围','请填写用户姓名','请填写身份证号','请填写联系电话','请选择省/市/区','请填写地址信息，如小区名称、楼栋号、单元等信息'],
        Tips:['文字清晰，大小不超5M','我已阅读并了解','申请入驻协议','入驻申请提交成功！','请耐心等待工作人员审核','请填写店铺名称！','您输入的店铺名称包含特殊字符，请重新输入！','请填写店铺信息！','请填写经营范围！','请填写用户姓名！','您输入的用户姓名包含特殊字符，请重新输入！','请填写身份证号！','请填写联系电话！','您输入的联系电话错误，请重新输入！','请选择所在地区！','请填写详细地址！','请勾选入驻协议！']
    },
    message:{
        title:'我的消息',
        title2:'消息详情',
        system:'系统通知',
        look_info:'查看详情',
        Tips:['你还没有任何消息哦','删除成功']
    },
    my:{
        tab:['交易管理','我的订单','售后管理','我的资产','优惠券','我的钱包','我的积分','账号管理','我的消息','我的收藏','收货地址','个人资料','账号安全'],
        Tips:'该功能暂未上线！',
		bankCard: "银行卡"
    },
    aboutUs:{
        tab:['常见问题','售后问题','支付问题','新手指南','购物流程','支付方式','售后服务','退货政策','取消订单','退款流程','退款说明','关于我们'],
        Tips:'暂无内容，请前往管理平台设置！'
    },
    myinfor:{
        title:'个人资料',
        user_avatars:'用户头像',
        edit_avatars:'编辑头像',
        nickname:'昵 称',
        sex:'性 别',
        radio:['男','女'],
        phone:'手机号码',
        Account_security:'账号安全',
        birth:'出生日期',
        set_avatars:'设置头像',
        click:'点击上传图片',
        placeholder:['请输入昵称','选择日期'],
        Tips:['注：修改手机号码请到','支持JPEG/BMP/PNG','请上传图片！','请上传正确的文件','修改成功']
    },
    shoppingCart:{
        title:'首页',
        title2:'购物车',
        login:'立即登录',
        checkbox:'全选',
        coupons:'优惠券',
        fold:'折',
        add:'购物车增减',
        remover:'移到我的收藏',
        piece:'件',
        settlement:'去结算',
        go:'去逛逛',
        Recommend:'为你推荐',
        batch:'换一批',
        shelf:'已下架',
        add_shop:'添加商品',
        num:'数量',
        long:['删除选中商品','已选商品','总价(不含运费)：'],
        th:['商品信息','单价','数量','金额（元）','操作'],
        Tips:['您还没有登录，登录后同步购物车中的商品','购物车内暂时还没有商品，赶紧去逛逛','或者先登录同步之前加入的商品','确认要删除该商品吗？','确认要删除选中的商品吗？',
        '确定要移到我的收藏吗？','商品移入我的收藏后，将不在购物车显示','修改商品属性','领取成功','库存不足，请选择其它!','请选择属性！','添加成功','库存清单不存在此属性',
        '请选择商品','成功','修改失败！','']
    },
    security:{
        title:'账号安全',
        login_password:'登录密码',
        modify:'修改',
        Payment_password:'支付密码',
        set:'设置',
        phone:'手机号码',
        modify_phone:'修改手机号码',
        next:'下一步',
        Back:'返回',
        stepsList: ['身份验证', '修改手机', '修改完成'],
        phone2:['原手机号','新手机号','验证手机'],
        password:['原密码','新密码','确认密码','验证码'],
        placeholder:['请输入原密码','请输入新密码','请再次输入新密码','图片验证码','手机验证码','获取验证码','请输入6位数的新密码','请输入新的手机号码',''],
        long:['定期更换密码有助于账号安全','已设置支付密码','暂未设置支付密码','您验证的手机','修改登录密码','验证手机','温馨提示：为了安全起见，需进行身份验证，如遇到问题请联系客服'],
        Tips:['修改成功','请输入手机验证码！','请输入6位数的新密码！','密码不能包含空格，请重新输入！','确认密码不一致，请重新输入！','请填写验证码','请填写正确的验证码','请填写新手机','请填写手机验证码','请输入确认密码','密码不能小于6位','密码不能包含空格，请重新输入！','请输入正确的验证码','修改成功']
    },
    myorder:{
		hirt: '提示',
		isDelOrder: '是否删除此订单？',
        tab:['全部订单','待付款','待发货','待收货','待评价'],
        search:'搜索',
        placeholder:'请输入商品名称或订单号搜索',
        shop:'店铺：',
        order_number:'订单号：',
        self:'自提',
        add_time:'下单时间：',
        integral:'积分',
        pt:'拼团',
        ms:'秒杀',
        th:['商品信息','单价','数量','实付款','订单状态','操作'],
        state:['待评论','退货退款','退款','换货','审核中','审核通过','拒绝','已回寄','成功','售后结束','交易成功','订单失效','交易关闭','已完成'],
        order_info:'订单详情',
        after_info:'售后详情',
        self_address:'自提地址',
        time:'营业时间：',
        logistics:'查看物流',
        view_more:'查看更多',
        awb:'运单号',
        button:['立即付款','取消订单','提醒发货','确认收货','立即评论','查看提取码','追加评价','评价完成'],
        long:['你还没有相关的订单哦','含运费：','您一共有','个包裹','暂时还没有物流信息哦','扫码下载','来客推APP','订单物流状况实时跟踪','订单编号：','属性：','共计：','件，合计：','提取码：'],
        Tips:['剩余0分0秒','小时','分','秒']
    },
    orderdetails:{
        title:'订单详情',
        Current:'当前状态',
        state:['待付款','已付款','待收货','待评论','退货','交易成功','订单失效','交易关闭','已完成','审核中','审核通过','退货完成','已退款','拒绝原因：','失效原因：取消订单','失效原因：订单超时','关闭原因：系统定时关闭'],
        button:['立即付款','取消订单','提醒发货','确认收货','查看评论','批量申请售后'],
        logistics:['物流信息','包裹','物流公司：','运单号码：','物流跟踪：','收起','显示全部'],
        Receiving_info:['收货信息','收货人：','联系电话：','收货地址：','自提地址：'],
        order_info:['订单信息','订单号：','支付金额：','余额支付','支付宝支付','微信支付','下单时间：'],
        th:['商品信息','单价','数量','操作'],
        shop:['店铺名称：','运单号','商品名称：','商品属性名称：','申请售后','查看售后','商品名称：','商品属性名称：',''],
        order_end:['商品总价：','运费：','店铺优惠：','优惠：','会员折扣优惠：','实付金额：'],
        long:['还有','进行付款，若未及时付款，系统将自动取消订单','扫码下载','来客推APP','订单进度实时掌握','暂时还没有物流信息哦'],
        Tips:['包裹','暂无！']
    },
    evaluation:{
        title:'评价',
        shop_info:'商品信息',
        state:['待评价','追加评价','已完成'],
        Product_rating:'商品评分',
        Commodity_evaluation:'商品评价',
        placeholder:'请输入您对该商品的评价',
        logn:['晒','单','上传图片','最多上传6张，每张大小不超5M，支持GIF,JPG,PNG,BMP格式','匿名评论','发表评论','发布成功','该提示','后自动消失'],
        Tips:['请给商品评分','请输入商品评价','图片最多上传6张']

    },
    after_Sale:{
        title:'申请售后',
        checkAll:'全选',
        th:['商品信息','单价','数量'],
        logn:['选择需要售后的商品','选择售后类型','仅退款','未收到货，或与客服协商同意仅退款','退货退款','已收到货，需要退还已收到的货物','换货','已收到货，需要更换已收到的货物'],
        Tips:'请选择售后商品'
    },
    after_details:{
        title:'申请售后',
        state:['退款','退货退款','退换货','审核中','审核通过','拒绝','商品寄回中','退款成功','拒绝并退回商品','换货商品已寄出','换货成功'],
        order:'订单',
        number:'号',
        tiem:'申请时间：',
        Refund_amount:'退款金额',
        Refund_instructions:'退款说明',
        reason:'换货原因',
        upload:'上传图片',
        Upload_voucher:'上传凭证',
        yuan:'元',
        Submit:'提交申请',
        stepsList: [
            ['申请退款', '客服审核', '完成退款'],
            ['申请退款', '客服审核', '寄回商品', '商家收货', '完成退款'],
            ['申请退款', '客服审核', '寄回商品', '商家收货并寄出换货商品', '确认收货', '完成退换']
        ],
        placeholder:['请输入退款金额','请填写退款说明（最多200字）','请填写换货说明（最多200字）','请选择/填写快递名称','请输入快递单号','请输入联系人姓名','请输入联系人电话'],
        logistics:['，换货物流：','退货物流：','填写物流','确认收货','填写物流信息','快递名称','快递单号','联 系 人','联系电话'],
        long:['申请提交成功','请耐心等待工作人员审核','信息','申请售后商品','请按要求填写售后申请，以便快速通过审核','（最多','最多上传3张，每张大小不超5M，支持GIF,JPG,PNG,BMP格式'],
        th:['商品信息','单价','数量'],
        Tips:['请选择快递名称','请填写快递单号','请填写联系人姓名','请填写联系人电话','输入手机号错误，请重新输入！','请输入退款金额','退款金额不能大于可退金额','请输入退款说明','请输入换货原因','凭证最多上传3张','']
    },
    payment:{
        title:'立即付款',
        Amount_payable:'应付金额',
        yuan:'元',
        long:['请选择支付方式','余额支付','确认支付','暂未设置支付密码，立即设置或更换支付方式'],
        password:['输入密码','余额密码：','请输入密码'],
        set:'立即设置',
        Tips:['请输入密码！','密码错误!','支付成功']
    },
    homList:{
        title:'全部结果',
        home:'首页',
        emity:'清空筛选条件',
        pp:'品牌',
        all_pp:'所有品牌',
        more:'更多',
        Put:'收起',
        change:['单选','多选'],
        class:'分类',
        price:'价格',
        default:'默认',
        volume:'销量',
        shelf:'已下架',
        long:['共搜到商品','个','已选条件：'],
        tj:['新品','热销','推荐'],
		noPros: ['没有找到相关的搜索结果','请换个条件试试']
    },
    navright:['联系客服','下载APP','下载来客电商APP','返回顶部'],
    footer:['7X9小时在线客服','闪电发货','扫码下载来客推APP','扫码体验来客小程序'],
    homedetail:{
        title:'首页',
        shelf:'已下架',
        price:'价格',
        Collect_coupons:'领券',
        more:'更多',
        preferential:'优惠',
        num:'数量',
        addcart:'加入购物车',
        toBuy:'立即购买',
        collection:'收藏',
        Already_collected:'已收藏',
        share:'分享',
        goMch:'进店逛逛',
        sale:'在售商品',
        Sold:'已售',
        empty:'商品已售空',
        people:'关注人数',
        recommend:'相关推荐',
        Go:'去逛逛',
        long:['商品过期不存在，去首页逛逛','扫描进入来客电商H5','折','有效期'],
        Tips:['领取成功','库存不足，请选择其它','请选择属性！','添加成功','商品过期不存在！','新品','热销','推荐'],
        product:['商品详情','商品评价','全部评价','有图','有追评','购买','天后追评'],
		rxtj: "热销推荐",
		xprm: "新品热卖"
    },
    paydetails:{
        title:['首页','购物车','确认订单'],
        Receiving_method:'收货方式',
        express:'快递',
        Self_Extraction:'自提',
        setDefault:'设为默认',
        modify:'修改',
        time:'营业时间：',
        placeholder:['请选择店铺优惠','请选择省/市/区','请输入信息地址信息，如小区名称、楼栋号、单元等信息','请输入收货人姓名','请输入手机号码'],
        edit:'编辑',
        add:'新增',
        set:'立即设置',
        Receiving_address:['收货地址','所在地区','详细地址','收 货 人','手机号码','设为默认地址'],
        pay:['支付方式','余额支付'],
        password:['输入密码','余额密码：','请输入密码'],
        th:['商品信息','单价','数量','金额'],
        Discount:['平台优惠券','张','请选择平台优惠','暂无可用优惠券','商品总价：','店铺优惠：','平台优惠：','会员折扣优惠：','应付金额：','商品总价：','运费：','优惠券：','应付金额：','提交订单'],
        long:['新增收货地址','收货人：','添加收货地址','显示更多地址','收起地址','确认商品信息','返回购物车修改','店铺优惠：','暂无可用优惠券','运费：','免邮','共','件商品，合计：','暂未设置支付密码，立即设置或更换支付方式','寄送至：'],
        Tips:['支付失败，是否前往查看订单！','提示','删除成功！','请选择所在地区','请填写详细地址','请填写收货人姓名','收货人姓名包含特殊字符','请填写手机号码','修改成功','添加成功','请输入密码！','密码错误!','支付成功','余额不足！','']
    },
    payResult:{
        view_order:'查看订单',
        Go:'继续逛逛',
        add_product:'添加商品',
        number:'数量',
        left:['扫码下载','来客推APP','订单进度实时掌握'],
        pay:['支付方式：','余额支付','微信支付','支付宝支付'],
        long:['您的订单已支付成功，我们将尽快处理','订单编号：','支付金额：','为你推荐','换一批','已下架'],
        Tips:['库存不足，请选择其它!','请选择属性！','添加成功','']
    },
    scanCode:['请及时付款，以便订单尽快处理！','交易号：','请在','小时','内付款，否则交易会被取消','实付：','支付宝扫一扫支付','微信扫一扫支付','支付成功'],
    myLoad:{
        title:'修改资料',
        tab:['优惠券','领券','钱包','充值','积分','领积分'],
        myorder:['我的订单','查看全部订单','待付款','待发货','待收货','待评价','售后/退款','全部订单'],
        long:['您提交了订单，等待付款','您提交了订单，等待发货','您的订单','已发货','待评论','退货','交易成功','已失效','已关闭','已评价','已完成','您买的东西太少了，这里都空空的，','快去挑选合适的商品吧'],
        button:['查看详情','查看物流','立即付款','提醒发货','确认收货','立即评价','追加评价']
    },

	seconds: {
		statusArr: ['已结束', '抢购中', '即将开抢', '已抢光'],
		seckillPrice: '抢购价',
		snapup: "立即抢购",
		havaTips: ['已有', '人抢购成功'],
		noSeckill: '该时段暂无秒杀商品哦~',
		scanCodeWX: '扫码进入小程序立即抢购',
		endArr: ['距离结束', '距离开始', '已结束'],
	}

}
export default zh_CN