<template>
  <!-- 路由为home时 -->
  <div class="footerWrap" :class="{mb190: $route.path == '/home'}" id="foot">
    <div class="zwbg"></div>
    <div class="footer">
      <div class="left">
        <div class="clearfix">
          <div class="fl" style="margin: 20px 60px 0 0">
            <img src="../static/common/footerlogo.png" alt />
          </div>
          <dl class="fl">
            <!-- <dt>联系方式</dt> -->
            <dd>版权所有：中华人民共和国商务部流通产业促进中心</dd>
            <dd>网站管理：全国农商互联产业联盟</dd>
            <dd>
              ICP备案编号：
              <a href="javascript:;">京ICP备20006705号</a>
            </dd>
            <dd>
              <img src="../static/common/gongan.png" alt />京公网安备
              11010602060203号
            </dd>
          </dl>
          <dl class="fl">
            <!-- <dt>合作单位</dt> -->
            <dd>技术支持：北京中智互联商务发展有限公司</dd>
            <dd>技术支持电话：010-62280959</dd>
            <dd>业务咨询电话：010-62280959</dd>
          </dl>
        </div>
        <div class="footerIcon">
          <img src="../static/common/footer1.jpg" alt />
          <img src="../static/common/footer2.jpg" alt />
          <img src="../static/common/footer3.jpg" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NsFoot"
};
</script>

<style lang="scss" scoped>
.footerWrap {
  width: 100%;
  min-width: 1400px;
  height: 224px;
  background-color: #2c2c2c;
  .zwbg {
    height: 21px;
  }
  .footer {
    max-width: 1450px;
    min-width: 1400px;
    height: 194px;
    margin: 0px auto 0;
    background: url(../static/common/footerbg.png) no-repeat 0px 27px;
    div {
      color: #999;
    }
    .left {
      margin: 8px 0 0 240px;
      dl {
        margin: 0 78px 0 0;
        dt {
          font-size: 20px;
          margin: 0 0 26px 0;
        }
        dd {
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 6px;
          a {
            color: var(--main-color);
          }
          img {
            margin-right: 6px;
          }
        }
      }
      .footerIcon {
        margin: 0px 0 0 164px;
        img {
          display: inlin-block;
          margin-right: 30px;
        }
      }
    }
    .right {
      margin: 30px 150px 0 0px;
      img {
        display: inline-block;
        width: 89px;
        height: 89px;
        background-color: #fff;
        margin-right: 19px;
      }
    }
  }
}
@media screen and (max-width: 1536px) {
  .mb190 {
    margin-bottom: 190px;
  }
}
</style>