<template>
  <div id="app">
    <ns-head v-if="visible"></ns-head>
    <router-view />
    <ns-foot v-if="visible"></ns-foot>
  </div>
</template>

<script>
import nsHead from "./components/head.vue";
import nsFoot from "./components/foot.vue";

export default {
  name: "App",
  components: {
    nsHead,
    nsFoot
  },
  data() {
    return {
      visible: true
    };
  },
  watch: {
    $route(to) {
      // 登录注册页面不显示公用头尾
      this.visible =
        this.$route.name != "NsLogin" && this.$route.name != "NsRegister";
      // 我的页面不显示banner
      this.$store.commit(
        "changeVisibleBanner",
        to.fullPath.indexOf("/my/") < 0
      );
    }
  }
};
</script>

<style>
@import "./style/var.css";
@import "./style/reset.css";
/* @import "./style/common.scss"; */
</style>
